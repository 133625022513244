import { Credentials, Authentication } from '../../../redux/session/authentication/types'
import { User } from '../../../types/user/User'
import request from '../backendRequest'

// Login user
export const loginUser = async (credentials: Credentials) => {
  return request<Authentication>({
    method: 'POST',
    url: '/login',
    data: credentials
  })
}

// Current user
export const getCurrentUser = async () => {
  return request<User>({
    method: 'GET',
    url: '/users/current'
  })
}

export const updateUser = async ({ id, ...user }: User) => {
  return request<User>({
    method: 'PUT',
    url: `/users/${id}`,
    data: user
  })
}

export const deleteUser = async (id: string) => {
  return request<User>({
    method: 'DELETE',
    url: `/users/${id}`
  })
}
