import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { backendClient } from './backendClient'

const request = async <T>(options: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse<T>) => {
    const { data } = response
    return data
  }

  const onError = (error: AxiosError) => {
    return Promise.reject(error)
  }

  return backendClient(options).then(onSuccess).catch(onError)
}

export default request
