import React, { useEffect, useState } from 'react'
import {
  BookOutlined,
  CalendarOutlined,
  MenuOutlined,
  NotificationOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { Button, Menu, Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useDocsURL } from '../../utils/docs'
import { JiraRequestModal } from '../../features/jira/components/JiraRequestModal'

const HelpMenu = () => {
  const { t } = useTranslation()
  const docsURL = useDocsURL()
  const [modalOpen, setModalOpen] = useState(false)
  const [menuCollapsed, setMenuCollapsed] = useState(false)

  useEffect(() => {
    const handleResize = _.throttle(() => {
      setMenuCollapsed(window.innerWidth < 558)
    }, 500)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const items = [
    {
      key: 'help-menu',
      className: 'help-menu',
      icon: <QuestionCircleOutlined />,
      children: [
        {
          key: 'docs',
          label: t('header:manual'),
          icon: <BookOutlined />,
          onClick: () => window.open(docsURL, 'noopener noreferrer')
        },
        {
          key: 'serviceRequest',
          label: t('header:contact'),
          icon: <NotificationOutlined />,
          onClick: () => setModalOpen(true)
        },
        {
          key: 'demo',
          label: t('header:demo'),
          icon: <CalendarOutlined />,
          onClick: () =>
            window.open(
              `https://calendly.com/finadeck/finadeck-demo?month=${dayjs().format('YYYY-MM')}`,
              '_blank',
              'noopener noreferrer'
            )
        }
      ]
    }
  ]

  const MenuItem = () => (
    <Menu className="header-menu" selectedKeys={[]} items={items} mode={menuCollapsed ? 'inline' : 'horizontal'} />
  )

  return (
    <>
      <JiraRequestModal open={modalOpen} onCreate={() => setModalOpen(false)} onCancel={() => setModalOpen(false)} />
      {menuCollapsed ? (
        <Popover className="burger-menu-icon" content={MenuItem()} placement="bottomRight">
          <Button type="text" icon={<MenuOutlined />} />
        </Popover>
      ) : (
        MenuItem()
      )}
    </>
  )
}

export default HelpMenu
