import { Contract } from '../../../types/contract/Contract'
import { User } from '../../../types/user/User'
import request from '../backendRequest'

export const getContract = async (contractId: number) => {
  return request<Contract>({
    method: 'GET',
    url: `/contracts/${contractId}`
  })
}

export const getContractUsers = async (contractId: string) => {
  return request<User[]>({
    method: 'GET',
    url: `/contracts/${contractId}/users`
  })
}
