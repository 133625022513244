import { PointOptionsObject, SeriesOptionsType } from 'highcharts'
import i18next from 'i18next'
import _ from 'lodash'
import { toHtml } from '../../../../../redux/context/customReports/utils'
import { FormatterType } from '../utils'
import { ChartYAxis } from './types'
import { CustomReportCategory } from '../../../../../redux/context/customReports/typesCategory'
import { CustomReportChartSection } from '../../../../../redux/context/customReports/typesSection'
import { CustomReportVariable, VariableRow } from '../../../../../redux/context/customReports/typesVariable'
import { getTitleByLanguage } from '../customReportTable/utils'
import { getLeafCategories, getDataSerie } from './utils'
import { Dimension } from '../../../../../types/dimension/Dimension'
import { transformTitle } from '../../../../../redux/context/customReports/transUtils'

type PathContext = {
  [key in string]?: string | string[] | number | number[]
} & {
  companyId?: string | string[] | number | number[]
  dimensionId?: string | string[] | number | number[]
  dimension?: Dimension
  title?: string
}

const getPathContext = (path?: string, categories?: CustomReportCategory[]): PathContext => {
  if (!path) return {}
  const context: PathContext = {}
  path.split('-')?.reduce((cats: CustomReportCategory[], key) => {
    const cat = cats?.[+key]
    if (!cat) return cats
    if (cat?.type === 'company') {
      context.companyId = cat.value
      context.company = cat.title
    } else if (cat?.type === 'dimension') {
      context.dimension = cat.dimension
      context.title = cat.title ?? cat.dimension?.name
    } else {
      context[`${cat.type}`] = cat.value
    }

    return cat?.children || []
  }, categories ?? [])
  return context
}

export const getDataColumns = (section: CustomReportChartSection) => {
  const { categories, variables } = section
  const leafs = getLeafCategories(categories)

  const allCategoryCompanies = _.uniq(leafs.map(leaf => getPathContext(leaf.id, categories).companyId))

  const zeroWidhSpace = '​'
  const nameRow = [
    null,
    ...leafs.map(cat => {
      const ctx = getPathContext(cat.id, categories)
      if (allCategoryCompanies.length > 1) {
        return `${toHtml(transformTitle(cat, categories))}${zeroWidhSpace}${ctx?.company}       
          `
      }
      return `${toHtml(transformTitle({ ...ctx, children: [cat] } as CustomReportCategory, categories))}`
    })
  ]

  const series = variables.map(v => {
    return [getTitleByLanguage(v as VariableRow), ...getDataSerie(v, leafs)]
  })

  return [nameRow, ...series]
}

export function dataLabelformatter(this: FormatterType) {
  return this.point.y?.toLocaleString(i18next.language)
}

const seriesMap = (item: CustomReportVariable | CustomReportCategory) => {
  return {
    ...item.style,
    yAxis: item?.style?.yAxis ?? ChartYAxis.left,
    dataLabels: { formatter: dataLabelformatter },
    style: {
      fontWeight: (item as CustomReportVariable).style?.fontWeight ? 'bold' : undefined,
      fontStyle: (item as CustomReportVariable).style?.fontStyle
    }
  } as unknown as Highcharts.SeriesOptionsType
}

export const getSeries = (sec: CustomReportChartSection): Highcharts.SeriesOptionsType[] => {
  const { categories, variables } = sec
  const leafs = getLeafCategories(categories)

  if (!!sec?.style?.switchRowsAndColumns === false) {
    return variables.map(seriesMap)
  }

  if (!!sec?.style?.switchRowsAndColumns === true) {
    return leafs.map(seriesMap)
  }
  return []
}

export type DrillData = { [key: string]: Highcharts.PointOptionsObject[] }

export const getMaxSectionSeriesValue = (section: CustomReportChartSection, yAxis = ChartYAxis.left) => {
  const dataColumns = section.variables
    .reduce((acc, curr, i) => {
      if ((curr.style?.yAxis || ChartYAxis.left) === yAxis) acc.push(i + 1)
      return acc
    }, [] as number[])
    .flatMap(i => getDataColumns(section)[i])
    .filter(Number)

  const maxVal = Math.max(...(dataColumns.flatMap(row => row) as number[]))
  return Math.abs(maxVal * 1.3)
}

export const labelFormatter = (options: (SeriesOptionsType | PointOptionsObject) & { style?: any }, name: string) => {
  const style = options?.style
  const inlineStyle = style
    ? Object.entries(style)
        .map(([key, value]) => `${key}: ${value}`)
        .join('; ')
    : ''
  return `<div style="${inlineStyle}">${name}</div>`
}
