import React, { Ref, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
  AppstoreAddOutlined,
  DeleteOutlined,
  DiffOutlined,
  DownloadOutlined,
  EditOutlined,
  FilePdfOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { Dropdown, MenuProps, Space, Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useBackend } from '../../../../services/backend'
import { CustomReport } from '../../../../redux/context/customReports/types'
import deleteConfirmModal from '../../../../components/Modal/deleteConfirmModal'
import { notificationAction } from '../../../../redux/middleware/actions'
import { autoDownload } from '../../../../utils/helpers'
import { multipleTableDownload, downloadMenuItems } from '../../../../utils/tableExport'
import { calculatedReportSelector, customReportDateSelector } from '../../../../redux/context/customReports/selectors'
import { contextCompanyIdSelector } from '../../../../redux/context/company/selectors'

interface CustomReportActionsProps {
  sectionListRef: Ref<any>
  isFinished: boolean
  editModal: (sec: any | null) => void
  handleDelete: (id?: number) => void
  openEdit: (report: CustomReport) => void
  setreportModalVisible: (visible: boolean) => void
}

const CustomReportActions: React.FC<CustomReportActionsProps> = ({
  sectionListRef,
  isFinished,
  handleDelete,
  openEdit,
  editModal,
  setreportModalVisible
}) => {
  const calculatedReport = useSelector(calculatedReportSelector)
  const date = useSelector(customReportDateSelector)
  const companyId = useSelector(contextCompanyIdSelector)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<{ key: string; action: Function } | undefined>()

  useEffect(() => {
    if (isFinished) {
      loading?.action()
      setLoading(undefined)
    }
  }, [isFinished])

  const reportPdfRequest = useBackend(
    `/companies/{companyId}/download/custom-report/{reportId}/pdf`,
    process.env.REACT_APP_DELIVERY_URL,
    'arraybuffer'
  )
  const reportXlsxRequest = useBackend(
    `/companies/{companyId}/download/custom-report/{reportId}/xlsx`,
    process.env.REACT_APP_DELIVERY_URL,
    'arraybuffer'
  )

  const downloadPdf = async () => {
    try {
      const response = await reportPdfRequest.get({
        urlParams: { companyId, reportId: calculatedReport?.id },
        body: {
          params: { date: dayjs(date).endOf('month').format('YYYY-MM-DD'), lng: i18next.language }
        }
      })
      autoDownload(`${calculatedReport?.title}-${dayjs(date).format('YYYY-MM')}.pdf`, response)
    } catch (error) {
      dispatch(notificationAction({ type: 'error', message: 'DOWNLOAD_ERROR' }))
    }
  }

  const getReportsAndDownload = async (key: string) => {
    multipleTableDownload(sectionListRef, calculatedReport?.title || 'omaRaportti', key)
  }

  const handleMenuClick = ({ key }: any) => {
    switch (key) {
      case 'edit':
        calculatedReport?.id && openEdit(calculatedReport)
        break
      case 'pdf':
        downloadPdf()
        break
      case 'delete':
        deleteConfirmModal({
          content: calculatedReport?.title,
          onOk: () => handleDelete(calculatedReport?.id)
        })
        break
      case 'csv':
      case 'xlsx':
        if (isFinished) {
          getReportsAndDownload(key)
        } else {
          setLoading({ key, action: getReportsAndDownload })
        }
        break
      default:
        break
    }
  }

  const menuProps: MenuProps = {
    items: [
      { icon: <EditOutlined />, label: t('global:edit'), key: 'edit' },
      {
        icon: <DownloadOutlined />,
        label: `${t('global:download')} ${t('global:file').toLowerCase()}`,
        key: 'download',
        children: [
          { label: 'PDF', key: 'pdf', icon: <FilePdfOutlined /> },
          ...downloadMenuItems.map(item => ({
            ...item,
            icon: loading && item.key === loading.key ? <Spin style={{ fontSize: '0.6rem' }} /> : item.icon
          }))
        ]
      },
      {
        icon: <DeleteOutlined />,
        danger: true,
        label: `${t('global:delete')} ${t('global:report').toLowerCase()}`,
        key: 'delete'
      }
    ],
    style: { zIndex: 9000 },
    onClick: handleMenuClick
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <span aria-hidden="true" onClick={() => setreportModalVisible(true)}>
          <DiffOutlined /> {t('global:report')}
        </span>
      )
    },
    {
      key: '2',
      label: (
        <span aria-hidden="true" onClick={() => editModal(null)}>
          <AppstoreAddOutlined /> {t('global:section')}
        </span>
      )
    }
  ]

  return (
    <Dropdown.Button
      key="dropDown"
      placement="bottomLeft"
      icon={reportPdfRequest.loading || reportXlsxRequest.loading ? <LoadingOutlined /> : undefined}
      menu={menuProps}
    >
      <Dropdown placement="bottom" menu={{ items }}>
        <Space>
          <PlusOutlined />
          {t('global:new')}
        </Space>
      </Dropdown>
    </Dropdown.Button>
  )
}

export default CustomReportActions
