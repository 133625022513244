import React from 'react'
import { useTranslation } from 'react-i18next'
import { GroupConfig, keyValueConfig } from './masterConfig'
import FieldDivider from './FiedDivider'

interface FormRendererProps {
  config?: GroupConfig
  switchRowsAndColumns?: boolean
}

const FormRenderer: React.FC<FormRendererProps> = ({ config, switchRowsAndColumns }) => {
  const { t } = useTranslation()
  if (!config) return null
  return (
    <div>
      {Object.entries(config).map(([key, fields]) => (
        <div key={key}>
          {fields.length > 1 ? <FieldDivider text={t(`customReportPage:${key}`)} /> : null}
          {fields.map((field: any) => (
            <div key={field}>
              {keyValueConfig[field]
                ? React.cloneElement(keyValueConfig[field].element, { switchRowsAndColumns })
                : null}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default FormRenderer
