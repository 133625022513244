import React, { useMemo } from 'react'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { Form, FormInstance } from 'antd'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { masterConfig, SectionConfig } from './masterConfig'
import { useAuthorizedData } from '../../../../../../utils/Authorizable/authorize'
import { formulasSelector } from '../../../../../../redux/context/formulas/selectors'
import { RowType, VariableFormValues } from '../../../../../../redux/context/customReports/typesVariable'
import { Formula } from '../../../../../../types/formula/Formula'
import { SectionType } from '../../../../../../redux/context/customReports/typesSection'
import FormRenderer from './FormRenderer'
import RowTypeSelect from './fields/RowTypeSelect'

interface VariableFormProps {
  form: FormInstance<VariableFormValues>
  chartStylesEnable?: boolean
}

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
}

const VariableForm = ({ form }: VariableFormProps) => {
  const { t } = useTranslation()
  const parentForm = useFormInstance()
  const formulas = useSelector(formulasSelector)
  const config = useAuthorizedData(masterConfig)
  const switchRowsAndColumns = Form.useWatch(['style', 'switchRowsAndColumns'], parentForm)
  const sectionType = Form.useWatch('type', parentForm) as SectionType
  const rowType = Form.useWatch('rowType', form)
  const variableTitle = Form.useWatch('name', form)

  const handleNameChange = ({ name, ...rest }: { name: string; nameEn: string; nameFi: string; nameSv: string }) => {
    if (!variableTitle) {
      form.setFieldsValue({
        name
      })
    }
    form.setFieldsValue(rest)
  }

  const handleValuesChange = ({
    keyFigureId,
    formulaId,
    rowType: rt
  }: { keyFigureId: number; formulaId: number } & Partial<VariableFormValues>) => {
    if (formulaId) {
      const formula = formulas.find(f => f.id === formulaId) ?? ({} as Formula)
      handleNameChange(formula)
    }
    if (keyFigureId) {
      handleNameChange({
        name: t(`keyFigureStatement:${keyFigureId}`),
        nameFi: t(`keyFigureStatement:${keyFigureId}`, { lng: 'fi' }),
        nameEn: t(`keyFigureStatement:${keyFigureId}`, { lng: 'en' }),
        nameSv: t(`keyFigureStatement:${keyFigureId}`, { lng: 'sv' })
      })
    }
    if (rt) {
      if (rt === RowType.empty || rt === RowType.title) {
        form.setFieldValue(['params', 'showEmptyRows'], true)
      }
      form.setFieldsValue({ name: '', nameEn: '', nameFi: '', nameSv: '' })
    }
  }

  const selectableRowtypes = useMemo(
    () => (sectionType && config ? (Object.keys(config[sectionType] || {}) as Array<keyof SectionConfig>) : []),
    [sectionType]
  )

  return (
    <Form
      name="customReportVariableForm"
      form={form}
      onValuesChange={handleValuesChange}
      style={{ width: 500 }}
      layout="vertical"
      {...layout}
    >
      <RowTypeSelect rowTypes={selectableRowtypes} />
      <FormRenderer config={config[sectionType]?.[rowType]} switchRowsAndColumns={switchRowsAndColumns} />
    </Form>
  )
}

export default VariableForm
