import { Divider, Typography } from 'antd'
import React from 'react'

interface FieldDividerProps {
  text: string
}

const FieldDivider: React.FC<FieldDividerProps> = ({ text }) => {
  return (
    <Divider orientation="left">
      <Typography.Title level={5}>{text}</Typography.Title>
    </Divider>
  )
}

export default FieldDivider
