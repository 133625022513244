import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { fetchJiraRequestTypes } from '../service/jiraService'
import jiraQueryKeys from './jiraQueryKeys'
import { JiraServicedeskRequestType } from '../../../types/jira/jira'

export const useUseJiraRequestTypes = <TData = JiraServicedeskRequestType[]>(): UseQueryResult<TData> => {
  return useQuery({
    queryKey: jiraQueryKeys.requestTypes,
    queryFn: fetchJiraRequestTypes,
    select(data) {
      const requestOrder = ['3', '9', '6']
      return data.values.sort((a, b) => requestOrder.indexOf(a.id) - requestOrder.indexOf(b.id)) as TData
    }
  })
}
