import { Button, Form, Popconfirm, Segmented, Space, Table } from 'antd'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CopyOutlined, DeleteOutlined, EditOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { NamePath } from 'antd/lib/form/interface'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { CategoryContext, CategoryContextType } from '../categorySelection/CategoryContext'
import DraggableTableRow from '../../../../../components/Table/DraggableTableRow'
import SectionFormVariableModal from './SectionFormVariableModal'
import { ReportDataType } from '../../../../../redux/context/reports/types'
import { ChartYAxis } from '../chart/types'
import {
  CustomReportVariable,
  VariableRow,
  VariableParams,
  RowType,
  CustomReportDataType
} from '../../../../../redux/context/customReports/typesVariable'
import { getTitleByLanguage } from '../customReportTable/utils'
import CodeBlock from '../../../../../components/Misc/CodeBlock'

interface VariableTableProps {
  open: boolean
  variableNamePath?: NamePath
  hanldeVariableDelete: (val: CustomReportVariable) => void
  setVariableNamePath: (value?: NamePath) => void
  setOpen: (open: boolean) => void
}

type CustomReportVariableFormItem = CustomReportVariable & {
  key: number
}

const VariableTable: React.FC<VariableTableProps> = ({
  open,
  variableNamePath,
  setOpen,
  hanldeVariableDelete,
  setVariableNamePath
}) => {
  const { t } = useTranslation()
  const form = Form.useFormInstance()
  const variables = (Form.useWatch('variables', form) || []) as CustomReportVariableFormItem[]
  const {
    budgetingScenarioMap,
    forecastMap,
    dimensionMap,
    categoryTree: { companies }
  } = useContext(CategoryContext) as CategoryContextType

  const dimensions = useMemo(() => {
    return companies.length === 1 ? dimensionMap[companies[0]] : null
  }, [dimensionMap, companies])

  const copyVariable = (data: CustomReportVariable) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, name, ...rest } = data
    const vars = variables || []
    const nextId = Math.max(...vars.map(({ key }: { key: number }) => +key)) + 1

    const variableCopy = {
      ...rest,
      key: nextId.toString(),
      name: name ? `${name} (copy)` : undefined
    }

    form.setFieldsValue({
      variables: [...vars, variableCopy]
    })
  }

  const showVariableModal = (path?: NamePath) => {
    setVariableNamePath(path)
    setOpen(true)
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = variables.findIndex(i => i.key === active.id)
      const overIndex = variables.findIndex(i => i.key === over?.id)
      const newVariables = arrayMove(variables, activeIndex, overIndex)
      form.setFieldValue('variables', newVariables)
    }
  }

  return (
    <>
      <SectionFormVariableModal variableNamePath={variableNamePath} open={open} onCancel={() => setOpen(false)} />
      <Form.List name="variables">
        {(fields, { remove }) => (
          <DndContext onDragEnd={onDragEnd}>
            <SortableContext items={variables.map(i => i.key)} strategy={verticalListSortingStrategy}>
              <Table
                size="small"
                dataSource={variables.map(v => ({
                  ...v,
                  children: undefined
                }))}
                pagination={false}
                components={{
                  body: {
                    row: DraggableTableRow
                  }
                }}
              >
                <Table.Column<CustomReportVariable> key="key" width={30} />
                <Table.Column<CustomReportVariable>
                  title={t('global:name')}
                  dataIndex="name"
                  ellipsis
                  render={(title, row) =>
                    row.rowType === RowType.empty ? t('table:empty-row') : getTitleByLanguage(row as VariableRow)
                  }
                />
                <Table.Column<CustomReportVariable>
                  ellipsis
                  title={t('global:dataType')}
                  dataIndex={['params', 'dataType']}
                  render={(
                    [dataType, id]: [VariableParams['dataType'], number | undefined] = [undefined, undefined]
                  ) => {
                    if (!dataType) return ''
                    const dataTypeMap = {
                      [ReportDataType.budget]: budgetingScenarioMap,
                      [ReportDataType.forecast]: forecastMap
                    }
                    const companyId = companies[0]
                    const { name = '' } = !(dataType === CustomReportDataType.actuals)
                      ? dataTypeMap?.[dataType]?.[companyId]?.find(b => b.id === (id as unknown as number)) || {}
                      : {}
                    return `${t(`global:${dataType}`, '')}${name ? ` / ${name}` : ''}`
                  }}
                />

                <Table.Column<CustomReportVariable>
                  title={t('global:dimension')}
                  dataIndex={['params', 'dimension', 'dimensionId']}
                  render={id => `${dimensions?.find(d => d.dimensionId === id)?.name || ''}`}
                />
                <Table.Column<CustomReportVariable> title="Offset" dataIndex={['params', 'offset']} width={80} />
                <Table.Column<CustomReportVariable>
                  title={t('global:formula')}
                  ellipsis
                  dataIndex={['source']}
                  render={source => {
                    return <CodeBlock code={source} />
                  }}
                />
                <Table.Column<CustomReportVariable>
                  title={t('global:yAxis')}
                  dataIndex="yAxis"
                  width={60}
                  render={(val, variable, index) => {
                    return (
                      <>
                        <Form.Item initialValue={ChartYAxis.left} noStyle key={index} name={[index, 'style', 'yAxis']}>
                          <Segmented
                            size="small"
                            options={[
                              {
                                label: <LeftOutlined />,
                                value: ChartYAxis.left
                              },
                              {
                                label: <RightOutlined />,
                                value: ChartYAxis.right
                              }
                            ]}
                          />
                        </Form.Item>
                      </>
                    )
                  }}
                />
                <Table.Column<CustomReportVariable>
                  key="actions"
                  title={t('global:actions')}
                  dataIndex="actions"
                  width={80}
                  render={(val, variable, index) => (
                    <Space>
                      <Button type="text" size="small" onClick={() => copyVariable(variable)} icon={<CopyOutlined />} />
                      <Button
                        size="small"
                        type="text"
                        icon={<EditOutlined />}
                        onClick={() => {
                          showVariableModal(['variables', index])
                        }}
                      />
                      <Popconfirm
                        placement="leftBottom"
                        title={t('global:delete-confirm')}
                        onConfirm={() => {
                          hanldeVariableDelete(variable)
                          remove(index)
                        }}
                        okText={t('global:yes')}
                        cancelText={t('global:cancel')}
                      >
                        <Button size="small" danger type="text" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </Space>
                  )}
                />
              </Table>
            </SortableContext>
          </DndContext>
        )}
      </Form.List>
      <Button type="dashed" block htmlType="button" onClick={() => showVariableModal()}>
        {t('customReportPage:add-row')}
      </Button>
    </>
  )
}

export default VariableTable
