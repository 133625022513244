import { QueryFunctionContext } from '@tanstack/react-query'
import request from '../../../services/api/backendRequest'
import dashboardEndpoints from '../api/dashboardEndpoints'
import dashboardQueryKeys from '../queries/dashbordQueryKeys'
import { DELETE, GET, POST, PUT } from '../../../redux/middleware/types'
import { MultiDashboard } from '..'
import { Orderable } from '../../../types/orderable/Orderable'
import { CompanyDashboard } from '../../../types/dashboard/company/types'

// Company

export const updateCompanyDashboardsOrder = async ({
  data,
  companyId
}: {
  data: Partial<CompanyDashboard>[]
  companyId: string
}) => {
  return request<Orderable[]>({
    method: PUT,
    url: dashboardEndpoints.companyOrder(companyId),
    data
  })
}

// Companies a.k.a multi-company

export const fetchCompaniesDashboards = async ({
  signal
}: QueryFunctionContext<ReturnType<(typeof dashboardQueryKeys)['companies']>>) => {
  return request<MultiDashboard[]>({
    method: GET,
    url: dashboardEndpoints.companies,
    signal
  })
}

export const createCompaniesDashboard = async (data: Partial<MultiDashboard>) => {
  return request<MultiDashboard>({
    method: POST,
    url: dashboardEndpoints.companies,
    data
  })
}

export const updateCompaniesDashboard = async (data: Partial<MultiDashboard>, id: number) => {
  return request<MultiDashboard>({
    method: PUT,
    url: dashboardEndpoints.companiesDetail(id),
    data
  })
}

export const deleteCompaniesDashboard = async (id: number) => {
  return request<MultiDashboard>({
    method: DELETE,
    url: dashboardEndpoints.companiesDetail(id)
  })
}

export const updateCompaniesDashboardsOrder = async (data: Orderable[]) => {
  return request<Orderable[]>({
    method: PUT,
    url: dashboardEndpoints.companiesOrder,
    data
  })
}

// Dimension

export const fetchDimensionDashboards = async ({
  queryKey: [{ companyId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof dashboardQueryKeys)['dimensions']>>) => {
  return request<MultiDashboard[]>({
    method: GET,
    url: dashboardEndpoints.dimensions(companyId),
    signal
  })
}

export const createDimensionDashboard = async ({
  data,
  companyId
}: {
  data: Partial<MultiDashboard>
  companyId: string
}) => {
  return request<MultiDashboard>({
    method: POST,
    url: dashboardEndpoints.dimensions(companyId),
    data
  })
}

export const updateDimensionDashboard = async ({
  data,
  companyId,
  id
}: {
  data: Partial<MultiDashboard>
  companyId: string
  id: number
}) => {
  return request<MultiDashboard>({
    method: PUT,
    url: dashboardEndpoints.dimensionDetail(companyId, id),
    data
  })
}
export const deleteDimensionDashboard = async ({ companyId, id }: { companyId: string; id: number }) => {
  return request<MultiDashboard>({
    method: DELETE,
    url: dashboardEndpoints.dimensionDetail(companyId, id)
  })
}

export const updateDimensionDashboardsOrder = async ({ data, companyId }: { data: Orderable[]; companyId: string }) => {
  return request<Orderable[]>({
    method: PUT,
    url: dashboardEndpoints.dimensionsOrder(companyId),
    data
  })
}

// Scenario

export const fetchScenarioDashboards = async ({
  queryKey: [{ companyId }],
  signal
}: QueryFunctionContext<ReturnType<(typeof dashboardQueryKeys)['scenarios']>>) => {
  return request<MultiDashboard[]>({
    method: GET,
    url: dashboardEndpoints.scenarios(companyId),
    signal
  })
}

export const createScenarioDashboard = async ({
  data,
  companyId
}: {
  data: Partial<MultiDashboard>
  companyId: string
}) => {
  return request<MultiDashboard>({
    method: POST,
    url: dashboardEndpoints.scenarios(companyId),
    data
  })
}

export const updateScenarioDashboard = async ({
  data,
  companyId,
  id
}: {
  data: Partial<MultiDashboard>
  companyId: string
  id: number
}) => {
  return request<MultiDashboard>({
    method: PUT,
    url: dashboardEndpoints.scenarioDetail(companyId, id),
    data
  })
}

export const deleteScenarioDashboard = async ({ companyId, id }: { companyId: string; id: number }) => {
  return request<MultiDashboard>({
    method: DELETE,
    url: dashboardEndpoints.scenarioDetail(companyId, id)
  })
}

export const updateScenarioDashbordsOrder = async ({ data, companyId }: { data: Orderable[]; companyId: string }) => {
  return request<Orderable[]>({
    method: PUT,
    url: dashboardEndpoints.scenariosOrder(companyId),
    data
  })
}
