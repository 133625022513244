import { Cascader, FormItemProps } from 'antd'
import { t } from 'i18next'
import React, { useContext } from 'react'
import { generateDataTypeCascaderOptions } from '../../../../section/utils'
import { BudgetingScenario } from '../../../../../../../../types/budgetingScenario/BudgetingScenario'
import { CategoryContext, CategoryContextType } from '../../../../categorySelection/CategoryContext'
import { FormItem } from '../../../../../../../../components/Form/FormItemContext'

const DataType = (props: FormItemProps) => {
  const { budgetingScenarioMap, forecastMap, categoryTree } = useContext(CategoryContext) as CategoryContextType

  const companyId = categoryTree.companies[0]
  let budgets: BudgetingScenario[] = []
  let forecasts: BudgetingScenario[] = []
  if (companyId) {
    budgets = budgetingScenarioMap[companyId]
    forecasts = forecastMap[companyId]
  }

  return (
    <FormItem key="dataType" name={['params', 'dataType']} label={t('global:dataType')} {...props}>
      <Cascader options={generateDataTypeCascaderOptions(budgets, forecasts)} allowClear style={{ width: 200 }} />
    </FormItem>
  )
}

export default DataType
