import { Dictionary } from 'lodash'
import { LoanRepaymentMethod } from '../../../redux/entities/loanRepaymentMethods/types'
import { ContractProduct } from '../../../types/contract/Contract'
import { Industry } from '../../../types/industry/Industry'
import { InvestmentFundingSource } from '../../../types/investment/InvestmentFundingSource'
import { KeyFigure } from '../../../types/keyFigure/KeyFigure'
import { StatementRow } from '../../../types/statementRow/StatementRow'
import { Permission } from '../../../types/user/Permission'
import request from '../backendRequest'
import { Entities } from '../../../redux/entities/types'

export const getContractProducts = async () => {
  return request<Dictionary<ContractProduct>>({
    method: 'GET',
    url: '/contract-products'
  })
}

export const getIndustries = async () => {
  return request<Industry[]>({
    method: 'GET',
    url: '/industries'
  })
}

export const getInvestmentFundingSources = async () => {
  return request<InvestmentFundingSource[]>({
    method: 'GET',
    url: '/investment-funding-sources'
  })
}

export const getKeyFigures = async () => {
  return request<KeyFigure[]>({
    method: 'GET',
    url: '/key-figures/'
  })
}

export const getLoanRepaymentMethods = async () => {
  return request<LoanRepaymentMethod[]>({
    method: 'GET',
    url: '/loan-repayment-methods'
  })
}

export const getPermissions = async () => {
  return request<Permission[]>({
    method: 'GET',
    url: '/permissions'
  })
}

export const getStatementRows = async () => {
  return request<StatementRow[]>({
    method: 'GET',
    url: '/statement-rows'
  })
}

export async function fetchEntities() {
  try {
    const [
      contractProducts,
      industries,
      investmentFundingSources,
      keyFigures,
      loanRepaymentMethods,
      permissions,
      statementRows
    ] = await Promise.all([
      getContractProducts(),
      getIndustries(),
      getInvestmentFundingSources(),
      getKeyFigures(),
      getLoanRepaymentMethods(),
      getPermissions(),
      getStatementRows()
    ])

    // Return an object with the results
    return {
      contractProducts,
      industries,
      investmentFundingSources,
      keyFigures,
      loanRepaymentMethods,
      permissions,
      statementRows
    } as Entities
  } catch (error) {
    // Handle any error from the requests
    console.error('Error occurred while fetching entities:', error)
    throw error
  }
}
